.page {
    border: 1px #d3d3d3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.subpage {
    padding: 1cm;
    /* border: 5px red solid; */
    /* height: 257mm; */
    /* outline: 2cm #ffeaea solid; */
}

@page {
    size: A4;
    margin: 0;
}

@media print {
    .page {
        /* margin: 0 auto; */
        width: 210mm;
        height: 80vh;
        min-height: initial;
        background: white;
        /* page-break-after: always;
        page-break-inside: auto; */
        border: none;
        border-radius: 0px;
        background: white;
        box-shadow: none !important;
    }

    .subpage {
        /* padding: 1cm; */
        /* border: 5px red solid; */

        /* outline: 2cm #ffeaea solid; */
        /* page-break-after: always; */
    }

}